import React, {useEffect, useState} from "react";
import requester from "api/pesabackend"; 
import { useSelector } from "react-redux";

const resource_tag = 'billing';

const useGetInvoicesList = (pageNumber, business_branch_id) => {
    const [invoicesList, setInvoicesList] = useState([]);
	const [isLoadingInvoicesList, setLoadingInvoicesList] = useState(true);
	const [errorInvoicesList, setErrorInvoicesList] = useState(false);

	const url = `/${resource_tag}/invoices?search=False&business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setInvoicesList(response.data.data);
				setLoadingInvoicesList(false);
				setErrorInvoicesList(false);
			} catch (e) {
				setErrorInvoicesList(true);
				setLoadingInvoicesList(false);
			}
		};
		fetchData();
	}, [pageNumber]);
	return { invoicesList, isLoadingInvoicesList, errorInvoicesList };
};


const useGetCustomerInvoicesList = (pageNumber, business_branch_id) => {
    const [invoicesList, setInvoicesList] = useState([]);
	const [isLoadingInvoicesList, setLoadingInvoicesList] = useState(true);
	const [errorInvoicesList, setErrorInvoicesList] = useState(false);

	const url = `/${resource_tag}/invoices/customer?business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setInvoicesList(response.data.data);
				setLoadingInvoicesList(false);
				setErrorInvoicesList(false);
			} catch (e) {
				setErrorInvoicesList(true);
				setLoadingInvoicesList(false);
			}
		};
		fetchData();
	}, [pageNumber]);
	return { invoicesList, isLoadingInvoicesList, errorInvoicesList };
};

const useGetReceiptsList = (pageNumber, business_branch_id) => {
    const [receiptsList, setReceiptsList] = useState([]);
	const [isLoadingReceiptsList, setLoadingReceiptsList] = useState(true);
	const [errorReceiptsList, setErrorReceiptsList] = useState(false);

	const url = `/${resource_tag}/receipts?search=False&business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setReceiptsList(response.data.data);
				setLoadingReceiptsList(false);
				setErrorReceiptsList(false);
			} catch (e) {
				setErrorReceiptsList(true);
				setLoadingReceiptsList(false);
			}
		};
		fetchData();
	}, [pageNumber]);
	return { receiptsList, isLoadingReceiptsList, errorReceiptsList };
};

const useGetPaymentTypesList = (pageNumber, business_branch_id) => {
	const refreshCount = useSelector((state) => state.refreshCount);
    const [paymentTypesList, setPaymentTypesList] = useState([]);
	const [isLoadingPaymentTypesList, setLoadingPaymentTypesList] = useState(true);
	const [errorPaymentTypesList, setErrorPaymentTypesList] = useState(false);

	const url = `/${resource_tag}/payment_types?search=False&business_branch=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setPaymentTypesList(response.data.data);
				setLoadingPaymentTypesList(false);
				setErrorPaymentTypesList(false);
			} catch (e) {
				setErrorPaymentTypesList(true);
				setLoadingPaymentTypesList(false);
			}
		};
		fetchData();
	}, [refreshCount]);
	return { paymentTypesList, isLoadingPaymentTypesList, errorPaymentTypesList };
};


const useGetRefundsList = (pageNumber, business_branch_id) => {
	const refreshCount = useSelector((state) => state.refreshCount);
    const [refundsList, setRefundsList] = useState([]);
	const [isLoadingRefundsList, setLoadingRefundsList] = useState(true);
	const [errorRefundsList, setErrorRefundsList] = useState(false);

	const url = `/${resource_tag}/refunds?search=False&business_branch_id=${business_branch_id}`;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await requester.get(url);
				setRefundsList(response.data.data);
				setLoadingRefundsList(false);
				setErrorRefundsList(false);
			} catch (e) {
				setErrorRefundsList(true);
				setLoadingRefundsList(false);
			}
		};
		fetchData();
	}, [refreshCount]);
	return { refundsList, isLoadingRefundsList, errorRefundsList };
};

export {useGetInvoicesList, useGetReceiptsList, useGetPaymentTypesList, useGetRefundsList, useGetCustomerInvoicesList}