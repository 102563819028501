import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Sidebar from "scenes/global/NewSidebar";
import Navbar from "scenes/navbar";

import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import InventoryTab from "scenes/inventory/tabs/inventoryTab";
import InventorySetupTab from "scenes/inventory/tabs/inventorySetupTab";
import CustomerTab from "scenes/customer/tabs/customerTab";
import PurchaseTab from "scenes/purchase/tabs/purchaseTab";
import SupplierTab from "scenes/supplier/tabs/supplierTab";
import UserManagementTab from "scenes/userManagement/tabs/userManagementTab";
import AccountingTab from "scenes/accounting/tabs/accountingTab";
import HumanResourceTab from "scenes/humanResource/tabs/humanResourceTab";
import SettingsTab from "scenes/settings/tabs/settingsTab";
import BillingTab from "scenes/billing/tabs/billingTab";
import DashboardTab from "scenes/dashboards/tabs/dashboardTab";
import StockTransferTab from "scenes/stockTransfer/tabs/stockTransferTab";
import BusinessTab from "scenes/business/tabs/businessTab";
import UploadsTab from "scenes/upload/tabs/uploadTab";

// import PurchaseForm from "scenes/purchase/forms/purchaseForms/purchaseForm";
import InvoiceForm from "scenes/billing/forms/invoiceForm";
import InvoiceDetailsView from "scenes/billing/views/invoiceDetailView";
import ProductLog from "scenes/inventory/tables/productLogs";
import AdjustmentForm from "scenes/inventory/forms/adjustmentForms/stockAdjustmentForm";
import PurchaseOrderForm from "scenes/purchase/forms/purchaseOrderForms/purchaseOrderForm";
import PurchaseViewForm from "scenes/purchase/forms/purchaseForms/purchaseViewForm";
import PurchaseOrderViewForm from "scenes/purchase/forms/purchaseOrderForms/purchaseOrderViewForm";
import EditPurchaseOrderForm from "scenes/purchase/forms/purchaseOrderForms/editPurchaseOrderFom";
import EmployeeDetailsForm from "scenes/humanResource/forms/employeeForms/employeeDetailsForm";
import UserGroupCreateForm from "scenes/userManagement/forms/userGroupCreateForm";
import EditUserGroupPermssionForm from "scenes/userManagement/forms/editGroupPermisions";
import ReportsForm from "scenes/reports/forms";
import PayslipForm from "scenes/humanResource/forms/payslipForms/payslipForm";
import EditPayslipForm from "scenes/humanResource/forms/payslipForms/editPayslipForm";
import PurchaseRequisitionForm from "scenes/purchase/forms/purchaseRequisitionForms/purchaseRequisitionForm";
import EditPurchaseRequisitionForm from "scenes/purchase/forms/purchaseRequisitionForms/editPurchaseRequisitionForm";
import RefundForm from "scenes/billing/forms/refundForm";
import SupplierReturnForm from "scenes/purchase/forms/returns/supplierReturnForm";
import PurchaseDraftForm from "scenes/purchase/forms/purchaseDraftForms/purchaseDraftForm";
import EditPurchaseDraftForm from "scenes/purchase/forms/purchaseDraftForms/editPurchaseDraftForm";
import PurchaseDraftViewForm from "scenes/purchase/forms/purchaseDraftForms/purchaseDraftViewForm";
import StockTransferCreateForm from "scenes/stockTransfer/forms/createStockTransferForm";
import EditStockTransferForm from "scenes/stockTransfer/forms/editStockTransferForm";
import ProductUploadProgressScreen from "scenes/upload/forms/productUploadProcessForm";
import StockTakeUploadProgressScreen from "scenes/upload/forms/stockTakeUploadProcessForm";
import InitialStockUploadProgressScreen from "scenes/upload/forms/initialStockUploadProcessForm";

import ItemPurchaseDetailReport from "scenes/reports/tables/purchase/itemPurchaseDetailReport";
import ItemPurchaseSummativeReport from "scenes/reports/tables/purchase/itemPurchaseSummativeReport";
import ItemSalesSummativeReport from "scenes/reports/tables/sales/itemSalesSummativeReport";
import ItemSalesReport from "scenes/reports/tables/sales/itemSalesReport";
import AgingReport from "scenes/reports/tables/inventory/agingReport";
import ItemTrends from "scenes/reports/tables/inventory/itemTrends";
import RecievableReport from "scenes/reports/tables/accounting/recievables";
import PayablesReport from "scenes/reports/tables/accounting/payables";
import SupplierPurchaseReport from "scenes/reports/tables/purchase/supplierPurchaseReport";
import BatchwiseItemStockReport from "scenes/reports/tables/inventory/batchwiseStockReport";
import CustomerReturnReport from "scenes/reports/tables/returns/customerReturnReport";
import SupplierReturnReport from "scenes/reports/tables/returns/supplierReturnReport";
import DeadStockReport from "scenes/reports/tables/inventory/deadStockReport";
import StockAdjustmentReport from "scenes/reports/tables/inventory/stockAdjustmentReport";
import BalanceSheetView from "scenes/accounting/views/balanceSheetView";
import ProfitAndLossView from "scenes/accounting/views/profitAndLossView";

import ChartNextAccounts from "scenes/accounting/tables/chartNextAccounts";
import LedgerTransactionsTable from "scenes/accounting/tables/ledgerTransactionsTable";

function App() {
  const mode = useSelector((state) => state.mode);
  const token =  useSelector((state) => state.token);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [isSidebar, setIsSidebar] = useState(true);
  // const isAuth = token!==null ? true : false;
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            {isAuth ? (
              <>
              <Navbar setIsSidebar={setIsSidebar} />
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} container>
                <Box sx={{flexGrow: 1}}>
                  <Sidebar isSidebar={isSidebar} />
                </Box>
                <Box sx={{flexGrow: 999}}>
                  <main className="content">
                    <Box >
                    <Routes>
                      <Route path="/dashboards" element={<DashboardTab />} />
                      <Route path="/customer" element={<CustomerTab />} />
                      <Route path="/billing" element={<BillingTab />} />
                      <Route path="/invoiceForm" element={<InvoiceForm />} />
                      <Route path="/invoiceDetails" element={<InvoiceDetailsView />} />
                      <Route path="/suppliers" element={<SupplierTab />} />
                      <Route path="/purchase" element={<PurchaseTab />} />
                      <Route path="/inventory" element={<InventoryTab />} />
                      <Route path="/inventorySetup" element={<InventorySetupTab />} />
                      <Route path="/settings" element={<SettingsTab /> } />
                      <Route path="/userManagement" element={<UserManagementTab />} />
                      <Route path="/accounting" element={<AccountingTab /> }/>
                      <Route path="/chartNextAccounts" element={<ChartNextAccounts />} />
                      <Route path="/humanResource" element={<HumanResourceTab />} />
                      <Route path="/transfers" element={<StockTransferTab />} />
                      <Route path="/fileUploads" element={<UploadsTab /> } />
                      <Route path="/business" element={<BusinessTab /> }/>
                      <Route path="/reports" element={<ReportsForm />} />
                      <Route path="/profile/:userId" element={<ProfilePage /> } />
                      <Route path="/purchaseViewForm" element={<PurchaseViewForm />} />
                      <Route path="/purchaseDraftForm" element={<PurchaseDraftForm />} />
                      <Route path="/purchaseDraftViewForm" element={ <PurchaseDraftViewForm />} />
                      <Route path="/editPurchaseDraftForm" element={ <EditPurchaseDraftForm /> } />
                      <Route path="/productLogList" element={ <ProductLog /> } />
                      <Route path="/adjustmentForm" element={ <AdjustmentForm /> } />
                      <Route path="/purchaseOrderForm" element={ <PurchaseOrderForm /> }/>
                      <Route path="/editPurchaseOrderForm" element={ <EditPurchaseOrderForm /> } />
                      <Route path="/purchaseOrderViewForm" element={ <PurchaseOrderViewForm /> } />
                      <Route path="/payslipForm" element={ <PayslipForm /> } />
                      <Route path="/productUploadProgress" element={ <ProductUploadProgressScreen /> } />
                      <Route path="/stockTakeUploadProgress" element={ <StockTakeUploadProgressScreen /> } />
                      <Route path="/initialStockUploadProgress" element={ <InitialStockUploadProgressScreen /> } />
                      <Route path="/editPayslipForm" element={ <EditPayslipForm /> } />
                      <Route path="/purchaseRequisitionForm" element={ <PurchaseRequisitionForm /> } />
                      <Route path="/editPurchaseRequisitionForm" element={ <EditPurchaseRequisitionForm /> } />
                      <Route path="/refundForm" element={ <RefundForm /> } />
                      <Route path="/supplierReturnForm" element={ <SupplierReturnForm /> } />
                      <Route path="/stockTransferForm" element={ <StockTransferCreateForm /> } />
                      <Route path="/editStockTransferForm" element={ <EditStockTransferForm /> } />
                      <Route path="/employeeDetails" element={ <EmployeeDetailsForm /> } />
                      <Route path="/userGroup" element={ <UserGroupCreateForm /> } />
                      <Route path="/userGroupPermissions" element={ <EditUserGroupPermssionForm /> } />
                      <Route path="/itemPurchaseDetailReport" element={<ItemPurchaseDetailReport /> } />
                      <Route path="/itemPurchaseSummativeReport" element={<ItemPurchaseSummativeReport /> } />
                      <Route path="/balanceSheet" element={ <BalanceSheetView /> } />
                      <Route path="/payables" element={ <PayablesReport /> } />
                      <Route path="/profit&Loss" element={ <ProfitAndLossView /> } />
                      <Route path="/recievables" element={ <RecievableReport /> } />
                      <Route path="/agingReport" element={ <AgingReport /> } />
                      <Route path="/itemTrends" element={ <ItemTrends /> } />
                      <Route path="/supplierPurchase" element={<SupplierPurchaseReport /> } />
                      <Route path="/itemSalesReport" element={ <ItemSalesReport /> } />
                      <Route path="/itemSalesSummativeReport" element={<ItemSalesSummativeReport /> } />
                      <Route path="/batchwiseItemStockReport" element={<BatchwiseItemStockReport /> } />
                      <Route path="/deadStockReport" element={ <DeadStockReport /> } />
                      <Route path="/customerReturnReport" element={ <CustomerReturnReport /> } />
                      <Route path="/supplierReturnReport" element={ <SupplierReturnReport /> } />
                      <Route path="/stockAdjustmentReport" element={ <StockAdjustmentReport /> } />
                      <Route path="/ledgerTransactions" element={ <LedgerTransactionsTable /> } />
                    </Routes>
                    </Box>
                  </main>
                </Box>
                
								</Box>
                </>
            ) : (
              <main className="content">
								<Routes>
									{/* <Route path="/" element={<Navigate to="/login" />} /> */}
									<Route path="/" element={<LoginPage />} />
								</Routes>
							</main>
            )}
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
