import {Box,Paper,Alert,useTheme,useMediaQuery,Breadcrumbs,Link,Typography,Button,Card, CardContent} from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LinearWithValueLabel from "components/linearProgressBar";
import requester from "api/pesabackend";
import { useSelector } from "react-redux";
import { withoutSymbolFormatter } from "utilities/currencyFormatter";
import moment from "moment";
import { URL } from "api/pesabackend";

const ProductUploadProgressScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const file_id = location.state.id
  const date_added = location.state.date_added
  const uploaded_by = location.state.uploaded_by.first_name + " " + location.state.uploaded_by.last_name
  const number_of_rows = location.state.number_of_rows
  const processed_status =location.state.processed_status
  const flagged = location.state.flagged
  const estimated_time_of_upload = location.state.estimated_time_of_upload;
  const file_link = location.state.uploaded_file

  const [progress, setProgress] = useState(0);
  const [doneLoading, setDoneLoading] = useState(false);

  const checkProgress = async () => {

    const savedResponse = await requester.get( `/uploads/products/process?file_id=${file_id}`);

    if (savedResponse.status === 200) {
      setProgress(withoutSymbolFormatter(parseFloat(savedResponse.data.data.percentage)));
    }
    if (savedResponse.status === 201) {
      setProgress(withoutSymbolFormatter(parseFloat(savedResponse.data.data.percentage)));
    }
    if (savedResponse.status === 226) {
      setProgress(100)
      setDoneLoading(true);
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
        checkProgress()
    }, 3000);
    return () => clearTimeout(timer);
  }, [progress]);

  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Operation was successful!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} severity="error">
          Operation was not successful!
        </Alert>
      ) : null}

      <Box
        sx={{
          padding: "20px",
          marginX: "20px",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => navigate("/fileUploads")}
          >
            <ManageAccountsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            File Uploads
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            Upload Progress
          </Typography>
        </Breadcrumbs>
        
        <Paper
          sx={{ width: "100%", padding: "40px", margin: "20px" }}
          elevation={3}
        >
          <p style={{ color: "red" }}>*If you exit the screen before the file UPLOAD PROGRESS is at 100% the process will pause.</p>
          <Typography sx={{ mt: 2, mb: 1 }}>File Upload Progress </Typography>

          <LinearWithValueLabel progress={progress}/>

          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            marginY={"20px"}
          >
            <Card sx={{ gridColumn:"span 2" }}>
              <CardContent>
                <Typography variant="h4" sx={{ display: "flex", alignItems: "center" }} color="text.primary">File Upload Information </Typography>
                  Current Process Status: {processed_status ? "PROCESSED" :  "NOT PROCESSED"}
                <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }} color="text.primary">
                  Estimated Upload Time:  {estimated_time_of_upload} seconds
                </Typography>
                <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center" }} color="text.primary">
                    Number of rows in record: {number_of_rows}
                </Typography>
                <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center" }} color="text.primary">
                    Errors Flagged: {flagged? "YES" : "NONE"}
                </Typography>
                <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center" }} color="text.primary">
                    <a href={`${URL}${file_link}`}>Click Here To Download File</a>
                </Typography>
                <hr/>
                <Typography variant="overline" sx={{ display: "flex", alignItems: "center", justifyContent:"right" }} color="text.primary">
                    Uploaded by: {uploaded_by}
                  </Typography>
                  <Typography variant="overline" sx={{ display: "flex", alignItems: "center", justifyContent:"right" }} color="text.primary">
                      Date Uploaded: {moment(date_added).format("LLL")}
                  </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {doneLoading ?
              <Button
                onClick={() => navigate("/fileUploads")}
                variant="contained"
                color="success"
                size="small"
                sx={{
                  p: "0.5rem",
                }}
              >
                DONE
              </Button>
            : null}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ProductUploadProgressScreen;
