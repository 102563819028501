import React, {  useState, useEffect } from "react";
import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme, Alert, Breadcrumbs, Link, Typography, Paper} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux";
import PurchaseDetailForm from "./purchaseDraftDetailForm";
import {v4 as uuidv4} from 'uuid';
import moment from "moment/moment";
import { requester } from "api/pesabackend";
import SupplierForm from "scenes/supplier/forms/supplierForm";
import ResponsiveDialog from "components/responsiveDialog";

const purchaseSchema = yup.object().shape({
  invoice_number: yup.string().required("required"),
  detail: yup.string().nullable(),
  grand_total: yup.number().required("required"),
  purchase_date: yup.date().required("required"),
  related_supplier: yup.number().required("required"),
  products_purchased: yup.array()
  .of(
    yup.object().shape({
      related_product: yup.number().required("required"),
      related_product_unit: yup.number().required("required"),
      batch: yup.string(),
      expiry_date: yup.date(),
      quantity_purchased: yup.number().required("required"),
      amount: yup.number().required("required"),
      discount: yup.number().required("required"),
      })
  )
  .required('Please add a purchase record items')
  .min(1, 'At least one purchase must be added'),
});


const PurchaseDraftForm = (props) => {
  const userId = useSelector((state) => state.user.userId);
  const business_id = useSelector((state) => state.business_id);
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const token =  useSelector((state) => state.token);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({ name: "", mobile: "", address: "",})
  const [suppliers, setSuppliers] = useState([]);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    fetchSuppliers();
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const fetchSuppliers = async () => {
		try {
			const response = await requester.get(`/inventory/suppliers/list?business_branch=${business_branch_id}&search=false`);
			setSuppliers(response.data.data);
			setLoadingSuppliers(false);
		} catch (e) {
			setLoadingSuppliers(false);
		}
	} 

  const initialValuesPurchase = {
    invoice_number: "",
    detail: "",
    grand_total: 0,
    discount:0,
    purchase_date: moment().format('YYYY-MM-DD'),
    related_supplier: "",
    products_purchased:[
      {
        related_product: '', related_product_unit: '', batch: '', expiry_date: moment().format('YYYY-MM-DD'), quantity_purchased: 1, amount: 0,  discount: 0, data_id: uuidv4(),
      }
    ]
  };

  const[purchaseDetailFormData, setPurchaseDetailFormData] = useState([{
    quantity_purchased: '', amount: '',  discount: 0, data_id: uuidv4(),
  }]);

  const [totalDiscount,setTotalDiscount] = useState(initialValuesPurchase.discount);
  const [grandTotal,setGrandTotal] = useState(initialValuesPurchase.grand_total);
  

  const savePurchase = async (values, onSubmitProps) => {
    values.related_business = business_id
    values.related_business_branch = business_branch_id
    values.added_by = userId

    const savedItemResponse = await requester.post(`/inventory/purchase_draft/add`, JSON.stringify(values));

    if (savedItemResponse.data.status===201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();
        navigate("/purchase");
      }, 1500);
      
    };

    if (savedItemResponse.data.status===400) {
      setIsFailed(true)
      setIsFailedError(savedItemResponse.data.errors)
    };

  };


  const handleGrandTotal = (newArray) =>{
    const grandTotalCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.amount)
    }, 0);
    setGrandTotal(grandTotalCalculation);
  };

  const handleTotalDiscount = (newArray) =>{
    const totalDiscountCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.discount)
    }, 0);
    setTotalDiscount(totalDiscountCalculation);
  };

  

  const handleItemAmountChange = (event, index) =>{
    const {value} = event.target;
    const newArray = purchaseDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, amount: Number(value)}
        }
        return item
    })
    setPurchaseDetailFormData(newArray);
    handleGrandTotal(newArray);
    console.log(newArray)
  };
 

  const handleItemDiscountChange = (event, index) =>{
  const {value} = event.target;
    const newArray = purchaseDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, discount: value}
        }
        return item
    })
    handleTotalDiscount(newArray);
    setPurchaseDetailFormData(newArray);
  };

    

  const handleAddProductDetailSubmitData = () => {
    const oneRow = {
     quantity_purchased: '', amount: '', discount: 0, data_id: uuidv4()
    };
    setPurchaseDetailFormData([...purchaseDetailFormData, oneRow])
  }; 


  const handleRemoveProductDetailRow = (index) => {
    let newFormData = purchaseDetailFormData
    newFormData.splice(index,1);
    setPurchaseDetailFormData(newFormData);
    handleGrandTotal(newFormData);    
    handleTotalDiscount(newFormData);  
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await savePurchase(values, onSubmitProps);
  };
  
  return (
    <Box>
      {isSuccess ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="success">
          Purchase Invoice recorded successfully!
        </Alert>
      ) : isFailed ? (
        <Alert sx={{ m: "15px" }} variant="filled" severity="error">
          Operation was not successful!
        </Alert>
      ) : null}

      <Breadcrumbs sx={{ padding: "20px" }} aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/purchase")}
        >
          <ReceiptIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Purchase
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <b>Record Purchase Invoice Form</b>
        </Typography>
      </Breadcrumbs>
      <Paper sx={{ p: "10px" }}>
        <Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesPurchase}
            validationSchema={purchaseSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>Invoice Date: </label>
                      <TextField
                        label=""
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.purchase_date}
                        name="purchase_date"
                        type="date"
                        error={
                          Boolean(touched.purchase_date) &&
                          Boolean(errors.purchase_date)
                        }
                        helperText={
                          touched.purchase_date && errors.purchase_date
                        }
                        sx={{
                          width: "100%",
                          marginBottom: "15px",
                        }}
                      />
                    </Box>
                    <Box sx={{ display:"flex", flexDirection:"row", alignItems:"center" }}>
                      <TextField
                        label="Select Supplier"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.related_supplier}
                        name="related_supplier"
                        select
                        error={
                          Boolean(touched.related_supplier) &&
                          Boolean(errors.related_supplier)
                        }
                        helperText={
                          touched.related_supplier && errors.related_supplier
                        }
                        sx={{
                          width: "100%",
                          // marginBottom: "15px",
                        }}
                      >
                        {suppliers.map((supplierRecord, index) => {
                          return (
                            <MenuItem key={index} value={supplierRecord.id}>
                              {supplierRecord.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      <Button variant="contained" size="small" sx={{ m:"1rem", }} onClick={handleClickOpen}>Add New Supplier</Button>
                      <ResponsiveDialog
                        dialogButtonName = "Add Supplier"
                        dialogHeading = "Add a New Supplier"
                        open = {open}
                        handleClickOpen = {handleClickOpen}
                        handleClose = {handleClose}
                        negativeResponse = "Cancel"
                        positiveResponse = "Save"
                        content = {
                                      <SupplierForm formType="create" setOpen={setOpen} setIsSuccess={setIsSuccess} editData={editData}/>
                                  }
                        />
                    </Box>

                    <TextField
                      label="Invoice Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.invoice_number}
                      name="invoice_number"
                      error={
                        Boolean(touched.invoice_number) &&
                        Boolean(errors.invoice_number)
                      }
                      helperText={
                        touched.invoice_number && errors.invoice_number
                      }
                      sx={{
                        width: "100%",
                        marginBottom: "15px",
                      }}
                    />
                  </Box>

                  <TextField
                    label="Purchase Details"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline
                    rows={8}
                    value={values.detail}
                    name="detail"
                    error={Boolean(touched.detail) && Boolean(errors.detail)}
                    helperText={touched.detail && errors.detail}
                    sx={{
                      width: "40%",
                      marginTop: "15px",
                    }}
                  />
                </Box>
                <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="2px"
                  p="0.5rem"
                >
                  <h5>Input the products purchased here</h5>

                  <FieldArray
                    name="products_purchased"
                    render={(arrayHelpers) => {
                      const products_purchased = values.products_purchased;

                      return (
                        <>
                          {products_purchased.map((row, index) => {
                            return (
                              <PurchaseDetailForm
                                key={row.data_id}
                                index={index}
                                data={row}
                                handleRemoveProductDetailRow={(index) => {
                                  arrayHelpers.remove(index);
                                  handleRemoveProductDetailRow(index);
                                }}
                                handleItemAmountChange={handleItemAmountChange}
                                handleItemDiscountChange={
                                  handleItemDiscountChange
                                }
                                handleChange={handleChange}
                                errors={arrayHelpers.form.errors}
                                touched={arrayHelpers.form.touched}
                                name={`products_purchased[${index}]`}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                              />
                            );
                          })}

                          <Button
                            onClick={() => {
                              arrayHelpers.push({
                                related_product: "",
                                related_product_unit: "",
                                batch: "",
                                expiry_date: moment().format("YYYY-MM-DD"),
                                quantity_purchased: 1,
                                amount: 0,
                                discount: 0,
                                data_id: uuidv4(),
                              });
                              handleAddProductDetailSubmitData();
                            }}
                            sx={{
                              m: "1rem 0",
                            }}
                            size="small"
                            variant="contained"
                          >
                            Add Product
                          </Button>
                        </>
                      );
                    }}
                  />
                </Box>

                <Box
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="2px"
                  p="0.5rem"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "2px",
                    }}
                  >
                    <label>Grand Total: </label>
                    <TextField
                      label=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={(values.grand_total = grandTotal)}
                      name="grand_total"
                      disabled
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      error={
                        Boolean(touched.grand_total) &&
                        Boolean(errors.grand_total)
                      }
                      helperText={touched.grand_total && errors.grand_total}
                      sx={{
                        // width: "100%",
                        marginBottom: "15px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "2px",
                    }}
                  >
                    <label>Total Discount: </label>
                    <TextField
                      label=""
                      disabled
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={(values.discount = totalDiscount)}
                      name="discount"
                      type="number"
                      size="small"
                      error={
                        Boolean(touched.discount) && Boolean(errors.discount)
                      }
                      helperText={touched.discount && errors.discount}
                      inputProps={{
                        min: 0,
                      }}
                      sx={{
                        gridColumn: "span 1",
                        marginBottom: "15px",
                      }}
                    />
                  </Box>
                </Box>

                {/* BUTTONS */}
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{
                      m: "1rem",
                      p: "0.5rem",
                    }}
                  >
                    Save Purchase
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      navigate(`/purchase`);
                    }}
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{
                      m: "1rem",
                      p: "0.5rem",
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Paper>
    </Box>
  );
};

export default PurchaseDraftForm;
