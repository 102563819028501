import React from "react";
import {Box, Stack, Typography, TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FlexBetween } from "components/FlexBox";
import { currencyFormatter } from "utilities/currencyFormatter";

const InvoiceCreateView = ({total, customers, handleChangeCustomer, customer}) => {
  return (
          <Grid container spacing={4}>
            <Grid xs={12} sm={12}>
                <FlexBetween>
                  <Typography fontSize={16} variant="h4" sx={{marginBottom: 1}}>Invoice Amount - {currencyFormatter(parseFloat(total))}</Typography>
                </FlexBetween>
                <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "2px", padding: "0.5rem" }}>
                    <Grid xs={12} sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h6" component="div">
                            Enter the expected date of payment
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label="Date of Payment"
                            fullWidth={true}
                            variant="outlined"
                            margin="dense"
                            autoFocus
                        />
                      </Stack>
                    </Grid>
                  <Grid xs={12} sm={12}>
                    <FormControl fullWidth sx={{marginTop: 2}}>
                        <InputLabel id="demo-simple-select-label">Select A Customer to Attach Invoice</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="category-select"
                            value={customer}
                            label="Select Payment Type Category"
                            onChange={handleChangeCustomer}
                        >
                            {customers.map((value, index ) => {
                                return (
                                    <MenuItem key={index} value={value.id}>{`${value.related_user.first_name} ${value.related_user.last_name}`}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                  </Grid>
                </Box>
            </Grid>
          </Grid>
   );
};

export default InvoiceCreateView;